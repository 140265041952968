import React from "react"
import {Link} from "gatsby"
import {useStaticQuery, graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import {slugify} from "../helpers"

const ArticlesList = (props) => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulArticle {
        edges {
          node {
            markdown {
              childMarkdownRemark {
                html
              }
            }
            id
            title
            mainPitch
            thumbnailImage {
              localFile {
                url
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const articles = data.allContentfulArticle.edges

  return (
    <Layout>
      <SEO title="Articles"/>
      <div className="mx-auto container font-sans flex flex-col items-center justify-around section">
        <h2 className="text-center uppercase text-xl text-navy-500 font-thin mt-4 mb-4 lg:mt-8">Blog</h2>
        <p className="max-w-3xl text-center mx-auto text-navy-500 px-4 mb-6 lg:mb-8">
          Our Mission is to promote an Islamic way of life, as described in the Holy Quran and the Sunnah of Prophet Muhammad (Salallahu Alayhi Wasallam), through worship, learning and socialization.
        </p>
        <div className="flex flex-wrap items-center justify-center ">
          {articles.length > 0 && articles.filter(post => post.node.mainPitch !== true).map(({node: article}) =>
             <Link to={'/articles/' + slugify(article.title)}
                         key={article.id}
                         aria-label={article.name + ' Gwinnett Islamic Circle'}
                         className="mx-0 text-center text-navy-600 lg:mb-6 mb-10 w-full lg:max-w-md lg:min-w-md mx-4">

              {article.thumbnailImage && <Img
                imgStyle={{
                  objectFit: "cover",
                  objectPosition: "50% 0%",
                }}
                fluid={article.thumbnailImage.localFile.childImageSharp.fluid}
                className="h-40 md:h-64 rounded mx-auto"/>}

              <div className="font-bold text-lg py-2 h-20">{article.title}</div>
            </Link>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default ArticlesList